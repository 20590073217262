<template>
    <layout>
        <page-header :title="title" :items="items" />
        <div class="row">
            <div class="col-12 text-center mb-3">
                <router-link
                    :to="redirectLink"
                    class="btn w-sm btn-light waves-effect"
                >
                    Cancel
                </router-link>
                <button
                    type="button"
                    class="btn btn-success waves-effect waves-light ml-1"
                    @click="save"
                >
                    Save
                </button>
            </div>
        </div>
        <div class="card-box">
            <h5 class=" col-12 text-uppercase bg-light p-2 mb-3">
                General
            </h5>
            <div class="form-group col-12 row">
                <div class="col-6 mx-auto">
                    <label for="status">Question type</label>
                    <b-form-select v-model="question.type" :options="types" />
                </div>
            </div>
            <div class="form-group col-12">
                <wysiwyg-editor
                    id="title"
                    v-model="question.title"
                    :error-border="$v.question.title.$error"
                    label="Title"
                    @blur="$v.question.title.$touch"
                    @input="$v.question.title.$touch"
                />
                <div v-if="titleErrors.length" class="invalid-feedback">
                    {{ titleErrors[0] }}
                </div>
            </div>
            <div
                v-if="question.type !== 'video'"
                class="form-group col-12"
            >
                <wysiwyg-editor
                    id="subtitle"
                    v-model="question.subtitle"
                    label="Subtitle"
                />
            </div>
            <div class="form-group col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Title' and 'Subtitle' you can use variables from questionnaire. There are also predefined variables for first name -
                    <b>{Name.firstName}</b> and last name - <b>{Name.lastName}</b>.
                </div>
            </div>
            <div v-if="question.type === 'size'" class="form-group col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In size question every link will open <b>size chart</b>.
                </div>
            </div>

            <div
                v-if="question.type === 'short' || question.type === 'open'"
                class="form-group col-12"
            >
                <label for="placeholder">Placeholder</label>
                <input
                    id="placeholder"
                    v-model="question.placeholder"
                    class="form-control"
                    type="text"
                />
            </div>

            <div class="row">
                <div class="form-group col-4 ml-2">
                    <b-form-checkbox v-model="question.subquestion" switch>
                        Subquestion
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-if="question.type !== 'size'"
                        v-model="question.optional"
                        switch
                    >
                        Optional
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-if="question.type !== 'size'"
                        v-model="question.hide_in_summary"
                        switch
                    >
                        Hide in summary
                    </b-form-checkbox>
                    <b-form-checkbox v-model="question.copy_to_reduced" switch>
                        Copy to reduced
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-if="question.type === 'info'"
                        v-model="question.hide_answers"
                        switch
                    >
                        Hide email input
                    </b-form-checkbox>
                </div>
                <div v-if="question.type === 'single'" class="form-group col-4">
                    <b-form-checkbox v-model="question.product_related" switch>
                        Product related
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-if="question.product_related"
                        v-model="question.auto_add_product"
                        switch
                    >
                        Automatically add product to cart
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="question.activity_type_related"
                        switch
                    >
                        Activity type related
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="question.removable_inserts_related"
                        switch
                    >
                        Removable inserts related
                    </b-form-checkbox>
                    <b-form-checkbox v-model="question.hide_next_button" switch>
                        Hide next button
                    </b-form-checkbox>
                </div>
            </div>

            <div
                v-if="!question.hide_in_summary"
                class="form-group col-12"
            >
                <label for="summaryText">Summary text</label>
                <input
                    id="summaryText"
                    v-model="question.summary_text"
                    type="text"
                    :class="{
                        'is-invalid': $v.question.summary_text.$error
                    }"
                    class="form-control"
                    @input="$v.question.summary_text.$touch"
                    @blur="$v.question.summary_text.$touch"
                />
            </div>
            <div class="form-group col-12">
                <label for="answerVariable">Save answer to:</label>
                <input
                    id="answerVariable"
                    v-model="question.answer_variable"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    The best option for naming a variable is to put it in <b>{}</b>,
                    e.g. <b>{FirstName}</b>
                </div>
            </div>
            <div v-if="question.answer_variable" class="form-group col-12">
                <label for="checkoutField">Checkout field:</label>
                <b-form-select
                    id="checkoutField"
                    v-model="question.checkout_field"
                    :options="checkoutFields"
                />
            </div>

            <div class="form-group col-12">
                <label for="identifier">Question identifier:</label>
                <input
                    id="identifier"
                    v-model="question.identifier"
                    type="text"
                    class="form-control"
                />
            </div>

            <div class="form-group col-12">
                <label for="under_cta_text">Under CTA text:</label>
                <input
                    id="under_cta_text"
                    v-model="question.under_cta_text"
                    type="text"
                    class="form-control"
                />
            </div>

            <div v-if="question.type === 'single'" class="form-group col-12 ">
                <label>
                    Tooltip content
                </label>
                <wysiwyg-editor v-model="question.tooltip_text" />
            </div>

            <h5 class="text-uppercase bg-light col-12 p-2 my-3">
                Bubble Talk
            </h5>
            <div class="form-group col-12">
                <b-form-checkbox v-model="isBubbleTalk" switch>
                    Enabled
                </b-form-checkbox>
            </div>

            <question-bubble-talk
                v-if="isBubbleTalk"
                @bubble-talk="onAddBubbleTalk"
            />

            <h5 class="col-12 text-uppercase bg-light p-2 my-3">
                Analytics
            </h5>
            <div class="form-group col-12">
                <label for="question_step_name">Step Name</label>
                <input
                    id="question_step_name"
                    v-model="question.step_name"
                    type="text"
                    class="form-control"
                />
            </div>
            <div class="form-group col-12">
                <label>DataLayer Tag</label>
                <v-select
                    v-model="question.data_layer_tag"
                    :options="dataLayerTagOptions"
                />
            </div>
            <div class="form-group col-12">
                <b-form-checkbox v-model="question.short_answer" switch>
                    Send answer as YES / NO
                </b-form-checkbox>
            </div>
        </div>
        <div
            v-if="question.type === 'single' || question.type === 'multiple'"
            class="card-box"
        >
            <question-answers-options-table
                v-model="answerOptions"
                :parsed-options="answerOptions"
                :question-type="question.type"
                :product-related="question.product_related"
                :products="products"
                @clear-single-option="onClearSingleOption"
                @clear-all-options="onClearAllOption"
            />
        </div>

        <question-info-buttons
            v-if="question.type === 'info'"
            :info-next-button.sync="questionNextButton"
            :info-skip-button.sync="questionSkipButton"
        />

        <div v-if="question.type === 'loading'">
            <question-loading-button :button-object.sync="questionNextButton" />
            <question-loading-messages-table v-model="answerOptions" />
        </div>

        <div v-if="question.type === 'quantity'">
            <question-quantity
                :value="question"
                :quantity-skip-button.sync="questionSkipButton"
                :validations="$v"
                @input="onQuestionQuantityInput"
            />
        </div>

        <question-video
            v-if="question.type === 'video'"
            v-model="question.video_options"
            :questions="questions"
        />
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import { Single, Multiple, Loading } from '@services/QuestionTypes';
import QuestionAddEditMixin from './question-add-edit.mixin.js';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    components: {
        WysiwygEditor
    },

    mixins: [QuestionAddEditMixin],

    data() {
        return {
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Questionnaires',
                    to: '/questionnaires'
                },
                {
                    text: 'Add question',
                    active: true
                }
            ],
            question: {
                optional: false,
                type: 'short',
                hide_in_summary: false,
                value: {}
            }
        };
    },

    async created() {
        this.questionnaireId = this.$route.params.questionnaireId;

        await this.fetchQuestions();
    },

    methods: {
        ...mapActions({
            addQuestion: 'questionnaires/storeQuestion'
        }),

        async save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            if (this.answerOptions) {
                const options = this.answerOptions.map(option => {
                    if (option.is_none_button) {
                        return option;
                    }

                    switch (this.question.type) {
                        case 'single':
                            return new Single(option);
                        case 'multiple':
                            return new Multiple(option);
                        case 'loading':
                            return new Loading(option);
                        default:
                            return null;
                    }
                });

                this.question.value = {
                    options: [...options]
                };
            } else if (
                this.question.type === 'single' ||
                this.question.type === 'multiple'
            ) {
                this.$toasterWarning('Please add options!');

                return;
            }

            if (this.questionNextButton) {
                this.question.value = {
                    ...this.question.value,
                    button: this.questionNextButton
                };
            }

            if (this.questionSkipButton) {
                this.question.value = {
                    ...this.question.value,
                    skipButton: this.questionSkipButton
                };
            }

            if (this.isBubbleTalk) {
                this.question = { ...this.question, ...this.bubbleTalk };
            } else {
                this.question = {
                    ...this.question,
                    bubble_background: null,
                    bubble_image: null,
                    bubble_text: null
                };
            }

            try {
                await this.addQuestion({
                    questionnaireId: this.questionnaireId,
                    questionData: this.question
                });

                this.$router.push({
                    path: this.redirectLink
                });
            } catch (err) {
                console.error(err);

                this.$toasterError(err.response.data.error);
            }
        }
    }
};
</script>
